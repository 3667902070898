import React, {Component, Fragment} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import ScrollToTop from "react-scroll-to-top";

import {Home} from './js/Home';
import About from './js/About';
import PortraitsCommercial from './js/portraits/PortraitsCommercial';
import PortraitsPeople from './js/portraits/PortraitsPeople';
import WeddingsTestimonials from './js/weddings/WeddingsTestimonials';
import WeddingsInfo from './js/weddings/WeddingsInfo';
import WeddingsFAQ from './js/weddings/WeddingsFAQ';
import WeddingsGallery from './js/weddings/WeddingsGallery';
import PortraitsEvents from './js/portraits/PortraitsEvents';
import PortraitsInfo from './js/portraits/PortraitsInfo';

class App extends Component {  
  render() { 
    return (
      <Fragment>
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/about" component={About} />

            <Route exact path="/weddings" component={WeddingsGallery} />
            <Route path="/weddings/info" component={WeddingsInfo} />
            <Route path="/weddings/faq" component={WeddingsFAQ} />
            <Route path="/weddings/testimonials" component={WeddingsTestimonials} />

            <Route exact path="/commercial" component={PortraitsCommercial} />
            <Route exact path="/portraits" component={PortraitsPeople} />
            <Route path="/portraits/events" component={PortraitsEvents} />
            <Route path="/portraits/info" component={PortraitsInfo} />
          </Switch>
        </Router>
        <ScrollToTop smooth color="#000000" />
      </Fragment>
    );
  }
}
export default App;
