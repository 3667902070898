import React, {Fragment} from 'react';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import {OpenNewWindowIcon} from './components/Icons';

const About = () => {
  return (
    <Fragment>
      <Navigation />
      <div className="layout-3-to-1 layout-spacing space-above">
        <h2>About Hello Moonglow</h2>
        <br /><br />
        <div className="description">
          <div>
            <p>
              Our work at Hello Moonglow largely draw inspiration from old school movies, fashion magazines and natural light. Capturing authenticity in relationships - both the relationships between people and the relationships we have with ourselves - is why we do what we do.
            </p>
            <br /><br />
            <h3>Team</h3>
            <p>
              Together with talented professionals in the creative industry, we collaborate and create on a contractual basis. This allows Hello Moonglow to not only take on projects that we are confident in delivering with allocated client resources, but also provides utmost flexibility catering to varying geographical or partnership needs. All of our partners are independent business owners. We are accustommed to flowing between joining forces to create together and taking on separate responsibilities in solitude. 
            </p>
            <br />
            <br /><br />
            <p>Feel free to explore and reach out to connect over a cuppa.</p>
          </div>
          {/* <img src="/images/about.jpeg" className="image" alt="" /> */}
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default About;
