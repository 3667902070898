// WEDDINGS //

export const polinaJack = [
  {
    src: "../../../images/weddings/polina-jack/1.jpg",
    width: 1,
    height: 1
  },
  {
    src: "../../../images/weddings/polina-jack/2.jpg",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/weddings/polina-jack/3.jpg",
    width: 10,
    height: 6.7
  },
  {
    src: "../../../images/weddings/polina-jack/4.jpg",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/weddings/polina-jack/5.jpg",
    width: 1,
    height: 1
  }
];

export const kullikeMarko = [
  {
    src: "../../../images/weddings/kullike-marko/1.jpg",
    width: 1,
    height: 1
  },
  {
    src: "../../../images/weddings/kullike-marko/2.jpg",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/weddings/kullike-marko/3.jpg",
    width: 10,
    height: 6.7
  },
  {
    src: "../../../images/weddings/kullike-marko/4.jpg",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/weddings/kullike-marko/5.jpg",
    width: 1,
    height: 1
  }
];

export const janeAlex = [
  {
    src: "../../../images/weddings/jane-alex/1.jpg",
    width: 1,
    height: 1
  },
  {
    src: "../../../images/weddings/jane-alex/2.jpg",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/weddings/jane-alex/3.jpg",
    width: 10,
    height: 6.7
  },
  {
    src: "../../../images/weddings/jane-alex/4.jpg",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/weddings/jane-alex/5.jpg",
    width: 1,
    height: 1
  }
];

export const jelizavetaZanis = [
  {
    src: "../../../images/weddings/jelizaveta-zanis/1.JPG",
    width: 1,
    height: 1
  },
  {
    src: "../../../images/weddings/jelizaveta-zanis/2.JPG",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/weddings/jelizaveta-zanis/3.JPG",
    width: 10,
    height: 6.7
  },
  {
    src: "../../../images/weddings/jelizaveta-zanis/4.JPG",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/weddings/jelizaveta-zanis/5.JPG",
    width: 1,
    height: 1
  }
];

export const denitsaKevin = [
  {
    src: "../../../images/weddings/denitsa-kevin/1.jpg",
    width: 1,
    height: 1
  },
  {
    src: "../../../images/weddings/denitsa-kevin/2.jpg",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/weddings/denitsa-kevin/3.jpg",
    width: 10,
    height: 6.7
  },
  {
    src: "../../../images/weddings/denitsa-kevin/4.jpg",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/weddings/denitsa-kevin/5.jpg",
    width: 1,
    height: 1
  }
];

export const kristineBen = [
  {
    src: "../../../images/weddings/kristine-ben/1.jpg",
    width: 1,
    height: 1
  },
  {
    src: "../../../images/weddings/kristine-ben/2.jpg",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/weddings/kristine-ben/3.jpg",
    width: 10,
    height: 6.7
  },
  {
    src: "../../../images/weddings/kristine-ben/4.jpg",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/weddings/kristine-ben/5.jpg",
    width: 1,
    height: 1
  }
];

export const kristineJarmo = [
  {
    src: "../../../images/weddings/kristine-jarmo/1.jpg",
    width: 1,
    height: 1
  },
  {
    src: "../../../images/weddings/kristine-jarmo/2.jpg",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/weddings/kristine-jarmo/3.jpg",
    width: 10,
    height: 6.7
  },
  {
    src: "../../../images/weddings/kristine-jarmo/4.jpg",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/weddings/kristine-jarmo/5.jpg",
    width: 1,
    height: 1
  }
];

export const irinaMaxim = [
  {
    src: "../../../images/weddings/irina-maxim/1.JPG",
    width: 1,
    height: 1
  },
  {
    src: "../../../images/weddings/irina-maxim/2.JPG",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/weddings/irina-maxim/3.JPG",
    width: 10,
    height: 6.7
  },
  {
    src: "../../../images/weddings/irina-maxim/4.JPG",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/weddings/irina-maxim/5.JPG",
    width: 1,
    height: 1
  }
];

export const begumVitali = [
  {
    src: "../../../images/weddings/begum-vitali/1.JPG",
    width: 1,
    height: 1
  },
  {
    src: "../../../images/weddings/begum-vitali/2.JPG",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/weddings/begum-vitali/3.JPG",
    width: 10,
    height: 6.7
  },
  {
    src: "../../../images/weddings/begum-vitali/4.JPG",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/weddings/begum-vitali/5.JPG",
    width: 1,
    height: 1
  }
];

export const ashleyJames = [
  {
    src: "../../../images/weddings/ashley-james/1.jpg",
    width: 1,
    height: 1
  },
  {
    src: "../../../images/weddings/ashley-james/2.jpg",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/weddings/ashley-james/3.jpg",
    width: 10,
    height: 6.7
  },
  {
    src: "../../../images/weddings/ashley-james/4.jpg",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/weddings/ashley-james/5.jpg",
    width: 1,
    height: 1
  }
];

export const teetOwen = [
  {
    src: "../../../images/weddings/teet-owen/1.jpg",
    width: 1,
    height: 1
  },
  {
    src: "../../../images/weddings/teet-owen/2.jpg",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/weddings/teet-owen/3.jpg",
    width: 10,
    height: 6.7
  },
  {
    src: "../../../images/weddings/teet-owen/4.jpg",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/weddings/teet-owen/5.jpg",
    width: 1,
    height: 1
  }
];

export const mariaFranklin = [
  {
    src: "../../../images/weddings/maria-franklin/1.jpg",
    width: 1,
    height: 1
  },
  {
    src: "../../../images/weddings/maria-franklin/2.jpg",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/weddings/maria-franklin/3.jpg",
    width: 10,
    height: 6.7
  },
  {
    src: "../../../images/weddings/maria-franklin/4.jpg",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/weddings/maria-franklin/5.jpg",
    width: 1,
    height: 1
  }
];

export const sandraGeorg = [
  {
    src: "../../../images/weddings/sandra-georg/1.jpg",
    width: 1,
    height: 1
  },
  {
    src: "../../../images/weddings/sandra-georg/2.jpg",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/weddings/sandra-georg/3.jpg",
    width: 10,
    height: 6.7
  },
  {
    src: "../../../images/weddings/sandra-georg/4.jpg",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/weddings/sandra-georg/5.jpg",
    width: 1,
    height: 1
  }
];

export const susannaBrendan = [
  {
    src: "../../../images/weddings/susanna-brendan/1.jpg",
    width: 1,
    height: 1
  },
  {
    src: "../../../images/weddings/susanna-brendan/2.jpg",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/weddings/susanna-brendan/3.jpg",
    width: 10,
    height: 6.7
  },
  {
    src: "../../../images/weddings/susanna-brendan/4.jpg",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/weddings/susanna-brendan/5.jpg",
    width: 1,
    height: 1
  }
];
