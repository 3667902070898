import React, {Fragment} from 'react';
import { TemplateHome } from '../components/Template';
import WeddingsNavigation from './WeddingsNavigation';

const WeddingsTestimonials = () => {
  return (
    <TemplateHome
      subNavigation={ <WeddingsNavigation /> }
      pageCategory="Weddings"
      pageTitle="Testimonials"
      instagramHandle="hello.moonglow"
      mainContent={
        <p className="body paragraph-center-reading">Coming soon...</p>
        // <Fragment>
        //   <p>Patricia is utterly delightful, so friendly and joyful, put us both as ease and made the whole experience very relaxing and fun. I am normally very awkward having my photograph taken and was a bit nervous, but thanks to Patricia I really enjoyed the photo shoot. She is excellent at direction, giving just enough input and allowing for our natural behaviour/characters, to create some beautiful photographs to celebrate our engagement. We can't recommend her enough, and we will most certainly be requesting her unquestionable talents at our wedding too!!! Thank you Patricia!!!! - Teet & Owen</p>
        // </Fragment>
      }
    />
  );
};

export default WeddingsTestimonials;
