// COMMERCIAL //

export const commercialNottingham = [
    {
      src: "../../../images/portraits/nottingham/1.jpg",
      width: 1,
      height: 1
    },
    {
      src: "../../../images/portraits/nottingham/2.jpg",
      width: 6.7,
      height: 10
    },
    {
      src: "../../../images/portraits/nottingham/3.jpg",
      width: 10,
      height: 6.7
    },
    {
      src: "../../../images/portraits/nottingham/4.jpg",
      width: 6.7,
      height: 10
    },
    {
      src: "../../../images/portraits/nottingham/5.jpg",
      width: 1,
      height: 1
    }
  ];

// PEOPLE //

export const irene = [
  {
    src: "../../../images/portraits/irene/1.JPG",
    width: 1,
    height: 1
  },
  {
    src: "../../../images/portraits/irene/2.JPG",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/portraits/irene/3.JPG",
    width: 10,
    height: 6.7
  },
  {
    src: "../../../images/portraits/irene/4.JPG",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/portraits/irene/5.JPG",
    width: 1,
    height: 1
  }
];

export const sofiyaOlesiaSolomiya = [
  {
    src: "../../../images/portraits/sofiya-olesia-solomiya/1.JPG",
    width: 1,
    height: 1
  },
  {
    src: "../../../images/portraits/sofiya-olesia-solomiya/2.JPG",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/portraits/sofiya-olesia-solomiya/3.JPG",
    width: 10,
    height: 6.7
  },
  {
    src: "../../../images/portraits/sofiya-olesia-solomiya/4.JPG",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/portraits/sofiya-olesia-solomiya/5.JPG",
    width: 1,
    height: 1
  }
];

export const ailing = [
  {
    src: "../../../images/portraits/ailing/1.JPG",
    width: 1,
    height: 1
  },
  {
    src: "../../../images/portraits/ailing/2.JPG",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/portraits/ailing/3.JPG",
    width: 10,
    height: 6.7
  },
  {
    src: "../../../images/portraits/ailing/4.JPG",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/portraits/ailing/5.JPG",
    width: 1,
    height: 1
  }
];

export const dion = [
  {
    src: "../../../images/portraits/dion/1.jpg",
    width: 1,
    height: 1
  },
  {
    src: "../../../images/portraits/dion/2.jpg",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/portraits/dion/3.jpg",
    width: 10,
    height: 6.7
  },
  {
    src: "../../../images/portraits/dion/4.jpg",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/portraits/dion/5.jpg",
    width: 1,
    height: 1
  }
];

export const helen = [
  {
    src: "../../../images/portraits/helen/1.JPG",
    width: 1,
    height: 1
  },
  {
    src: "../../../images/portraits/helen/2.JPG",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/portraits/helen/3.JPG",
    width: 10,
    height: 6.7
  },
  {
    src: "../../../images/portraits/helen/4.JPG",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/portraits/helen/5.JPG",
    width: 1,
    height: 1
  }
];

export const kimberly = [
  {
    src: "../../../images/portraits/kimberly/1.jpg",
    width: 1,
    height: 1
  },
  {
    src: "../../../images/portraits/kimberly/2.jpg",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/portraits/kimberly/3.jpg",
    width: 10,
    height: 6.7
  },
  {
    src: "../../../images/portraits/kimberly/4.jpg",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/portraits/kimberly/5.jpg",
    width: 1,
    height: 1
  }
];

export const sofiya = [
  {
    src: "../../../images/portraits/sofiya/1.JPG",
    width: 1,
    height: 1
  },
  {
    src: "../../../images/portraits/sofiya/2.JPG",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/portraits/sofiya/3.JPG",
    width: 10,
    height: 6.7
  },
  {
    src: "../../../images/portraits/sofiya/4.JPG",
    width: 6.7,
    height: 10
  },
  {
    src: "../../../images/portraits/sofiya/5.JPG",
    width: 1,
    height: 1
  }
];