import React from 'react';
import {NavLink} from 'react-router-dom';
import {ArrowDownIcon} from '../components/Icons';

const PortraitsNavigation = () => {
  return (
    <div className="sub-navigation-container">
      <ArrowDownIcon />
      <div className="sub-navigation">
        <NavLink exact to='/portraits'>
          <span>Gallery</span>
        </NavLink>
        <NavLink to='/portraits/events'>
          <span>Events</span>
        </NavLink>
        <NavLink to='/portraits/info'>
          <span>Info</span>
        </NavLink>
      </div>
      <hr />
    </div>
  );
};

export default PortraitsNavigation;
