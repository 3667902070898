import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';
import {Link} from 'react-router-dom';
import React, {Fragment} from 'react';
import Navigation from './components/Navigation';
import Footer from './components/Footer';

const HeaderText = props => {
  return (
    <div className="font-heading-container">
      {/* <h2 className="font-heading"></h2>
      <h2 className="font-title">
        {props.headerTextTitle}
      </h2>
      <h2 className="font-heading">
        {props.headerTextHeading1}
        <span />
        <span className="vertical-divider">|</span>
        <span />
        {props.headerTextHeading2}
      </h2> */}
    </div>
  );
}

const Introduction = props => {
  return (
    <div className="section">
      <h1 className="font-title">{props.introTitle}</h1>
      <div className="font-subtitle">{props.introSubTitle}</div>
      <span className="paragraph paragraph-centered">{props.introDesc}</span>
      <span className="font-heading">{props.introHeading}</span>
    </div>
  );
}

const Categories = props => {
  return (
    <div className="home-categories">
      <div className="three-col-container">
        <div className="categories-column">
          <img src="../../../images/misc/home-category-elopements.JPG" alt="A couple about to elope, click to view gallery of elopements." />
          <Link to='/gallery/weddings-elopements' className="font-title">{props.categoryTitle1}</Link>
          <span>{props.categoryDesc1}</span>
        </div>
        <div className="categories-column">
          <img src="../../../images/misc/home-category-weddings.jpg" alt="A couple who got married posing at the venue, click to view the gallery of weddings." />
          <Link to='/gallery/weddings-elopements' className="font-title">{props.categoryTitle2}</Link>
          <span>{props.categoryDesc2}</span>
        </div>
        <div className="categories-column">
          <img src="../../../images/misc/home-category-portraits.jpg" alt="A woman posing with fairy lights, click to view the gallery of portraits." />
          <Link to='/gallery/portraits-couples' className="font-title">{props.categoryTitle3}</Link>
          <span>{props.categoryDesc3}</span>
        </div>
      </div>
    </div>
  );
}

const Style = props => {
  return (
    <div className="section">
      <span className="paragraph paragraph-two-columns">
        <h3 className="font-title">{props.styleTitle}</h3>
        {props.styleDesc}
      </span>
      <span className="font-heading">
        {props.styleHeading}
        <a href="https://portraits.neptu.co/" target="_blank"> Neptú Portraits</a>
      </span>
    </div>
  );
}

const Collaboration = props => {
  return (
    <div className="section three-col-template">
      <div className="three-col-wrapper">
        <h3 className="font-title">{props.collabTitle}</h3>
        <span className="paragraph three-col-container">
          <div>
            <span className="font-decoration">01. {props.collab1}</span>
            <span className="font-subtitle">{props.collabSubTitle1}</span>
            <span className="paragraph">{props.collabDesc1}</span>
          </div>
          <div>
            <span className="font-decoration">02. {props.collab2}</span>
            <span className="font-subtitle">{props.collabSubTitle2}</span>
            <span className="paragraph">{props.collabDesc2}</span>
          </div>
          <div>
            <span className="font-decoration">03. {props.collab3}</span>
            <span className="font-subtitle">{props.collabSubTitle3}</span>
            <span className="paragraph">{props.collabDesc3}</span>
          </div>
        </span>
      </div>
      <img src="../../../images/misc/home-process.JPG" alt="A couple who got married posing at the venue, click to view the gallery of weddings." />
    </div>
  );
}

const HomeComponent = props => {
  return (
    <Fragment>
      <Navigation />
      <div className="homepage">
        {/* <p>
          Hello there, welcome to Hello Moonglow.<br />
          Hello Moonglow is about documenting powerful moments in the split second of intimate emotions and beauty in unlikely places.
        </p> */}
        <HeaderText {...props} />
        <Carousel
          showArrows={false}
          showThumbs={false}
          infiniteLoop={true}
          autoPlay={true}
          showStatus={false}
          useKeyboardArrows={true}
          centerMode={false}
          className="carousel-container"
          showIndicators={false}
          interval="4500"
        >
          <div className="header-image header-image-1" />  
          <div className="header-image header-image-2" />  
          <div className="header-image header-image-3" />  
          <div className="header-image header-image-4" />  
          <div className="header-image header-image-5" />  
          <div className="header-image header-image-6" />
          <div className="header-image header-image-7" />
          <div className="header-image header-image-8" />
          <div className="header-image header-image-9" />
          <div className="header-image header-image-11" />
          <div className="header-image header-image-12" />
          <div className="header-image header-image-13" />
          <div className="header-image header-image-14" />
          <div className="header-image header-image-15" />
        </Carousel>
      </div>
      <Footer />
    </Fragment>
  );
};

export const Home = () => {
  return (
    <HomeComponent 
      headerTextTitle="Finding love everywhere"
    />
  );
}
