import React from 'react';
import {NavLink} from 'react-router-dom';
import {ArrowDownIcon} from '../components/Icons';

const WeddingsNavigation = () => {
  return (
    <div className="sub-navigation-container">
      <ArrowDownIcon />
      <div className="sub-navigation">
        <NavLink exact to='/weddings'>
          <span>Gallery</span>
        </NavLink>
        <NavLink to='/weddings/info'>
          <span>Info</span>
        </NavLink>
        <NavLink to='/weddings/faq'>
          <span>FAQ</span>
        </NavLink>
        <NavLink to='/weddings/testimonials'>
          <span>Testimonials</span>
        </NavLink>
      </div>
      <hr />
    </div>
  );
};

export default WeddingsNavigation;
