import React, {Fragment} from 'react';
import { TemplateHome } from '../components/Template';
import WeddingsNavigation from './WeddingsNavigation';

const WeddingsInfo = () => {
  return (
    <TemplateHome
      subNavigation={ <WeddingsNavigation /> }
      pageCategory="Weddings"
      pageTitle="Packages Information & Service Details"
      instagramHandle="hello.moonglow"
      mainContent={
        <Fragment>
          <div className="layout-spacing layout-half">
            <div>
              <br />
              <br />
              <p>Hello there, lovely couple looking for a wedding photographer. Congratulations on arriving at yet another stage of your life where it's time to celebrate. Welcome to Hello Moonglow, where moments captured become more valuable with each passing year. If you're down to have a fun and genuine experience where you can remember your wedding day as you'd like, that's what I thrive at and love to photograph.</p>
              <br />
              <h5>Basically you ask me out as your third wheel and I say yes.</h5>
              <br />
              <p>Beauty is in everything and everywhere - in smiles and tears, in sunshine and rain. My approach to photography is to capture authentic emotion, connection, and beauty at your wedding. Each wedding tells a unique story and captures a moment in time. Just enjoy your day as you would and let me take care of the rest. Also known as a 'tag-along' photographer, people sometimes forget that I'm there (probably not as bad as it sounds). Yet, I'm always there for you. It is the style of authenticity and spontaneousness that I seize as your photographer.</p>
              <br />
              <h5>Our packages are also applicable for elopements, engagements or anniversaries.</h5>
              <br />
              <p>Let's jump on a call! I'd love to hear about your story, your vision and discuss making wicked beautiful pictures over tea. You deserve a curated experience that is catered to your needs. We'll review which package is most suitable for you and include add-ons as necessary.</p>
            </div>
            <div>
              <br />
              <br />
              <h3>The Process</h3>
              <h5>Step 01.</h5>
              <h4>Pick a package, get in touch</h4>
              <p>When you're ready, reach out to me with your venue and date. I'll reply within a day to set up a free consultation with you. During the consultation, I'll learn what you have in mind, discuss your vision and share recommendations with you.</p>
              <br />

              <h5>Step 02.</h5>
              <h4>Sign the agreement, complete booking</h4>
              <p>We'll sign a standard service agreement, you'll complete the booking fee which is 50% of the total service. Then the date will be reserved for you. 2 weeks before the wedding, I'll reach out to confirm any pending logistical details.</p>
              <br />

              <h5>Step 03.</h5>
              <h4>Enjoy the wedding, deliver of photos</h4>
              <p>On the day, I'll turn up and capture one of the most memorable days of your lives. The remaining 50% of the service would be due on the day. Sit tight and after the agreed-upon time, the photos will find their way to you.</p>
              <br />
            </div>
          </div>
          <br />
          <hr />
          <br />
          <div className="body paragraph-center-reading">
            <h3>The Packages</h3>
            <p>You're investing in a well-documented day by a photographer whom you can trust. I'm there rain or shine, gorgeous candlelit church or summer outdoor ceremony. The entire experience is just me treating your day like so: Really Freaking Important.</p>
          </div>
          <div className="layout-thirds">
            <div>
              <h4>Engagements</h4>
              <h5>Everlasting rememberance</h5>
              <ol>
                <li>1 - 2 hours</li>
                <li>Less than 10 guests</li>
                <li>Minimum 20 photos</li>
                <li>Delivery within 3 weeks</li>
                <li>Free tailored consultation</li>
                <li>High-res, edited & digital photos</li>
              </ol>
            </div>
            <div>
              <h4>Elopements</h4>
              <h5>Private and cozy nuptials</h5>
              <ol>
                <li>3 - 4 hours</li>
                <li>More than 10 guests</li>
                <li>Minimum 80 photos</li>
                <li>Delivery within 4 weeks</li>
                <li>Free tailored consultation</li>
                <li>High-res, edited & digital photos</li>
              </ol>
            </div>
            <div>
              <h4>Weddings</h4>
              <h5>With your loved ones</h5>
              <ol>
                <li>5 - 8 hours</li>
                <li>More than 40 guests</li>
                <li>Minimum 200 photos</li>
                <li>Delivery within 8 weeks</li>
                <li>Free tailored consultation</li>
                <li>High-res, edited & digital photos</li>
              </ol>
            </div>
          </div>
          <br />
          <div className="body paragraph-center-reading">
            <h3>Add-ons</h3>
            <p>I specialise in minimalist flat-rate packages as seen above so that you have maximum flexibility to get what you truly want. However, some sessions will require additional options. Below are extra add-ons for you to choose from depending on your needs:</p>
          </div>
          <div className="layout-thirds">
            <div>
              <h4>Videography</h4>
              <h5>When photos are not enough</h5>
              <ol>
                <li>Full ceremonial video</li>
                <li>Speech videos</li>
                <li>3-minute edited highlight video</li>
                <li>Beautifully colour-corrected & 4K high-def</li>
              </ol>
            </div>
            <div>
              <h4>Destination Weddings</h4>
              <h5>Including remote locations</h5>
              <ol>
                <li>Customised pricing based on travel and accommodation</li>
              </ol>
            </div>
            <div>
              <h4>Second Photographer</h4>
              <h5>Who will work under my lead</h5>
              <ol>
                <li>For when there are more than 100 guests</li>
              </ol>
            </div>
          </div>
          <br />
          <br />
          <br />
        </Fragment>
        // <div>
        //   <p>Elopement & Wedding Photography of relaxed, classic and candid moments.</p>
        //   <p>LGBTQ+ FRIENDLY: LOVE IS LOVE.</p>
        //   <p>inclusive of travel within X,
        //   <p>Upcoming trips</p>
        //   <p>
        //     Nothing can beat the emotions and charm of two people celebrating their love, and sharing that with their family and close friends. On wedding days and the days leading up to it, keeping things organized and cool under pressure is kinda my jam. Today, I run this dream of a business in cinematic and documentary wedding storytelling.
        //     01. Connection: You are not 'just another client' because I see us as partners in crime. The more you allow me in, the more personal and meaningful our photographs will become. I'm not photographing for you, I'm photographing with you. We need to do this together for it to work.
        //     02. Emotion: I want the photos to evoke deep emotions within yourselves, to create photographs that resonate with meaning and truth - where art and emotion are one and the same. Your journey here and your unique story is a precious one. Thank you for trusting me with it.
        //     03. Art: Fine art photography blended seamlessly with the stream of documentary photos. I'll bring the light, you'll bring the soul. And together, we do it the way we want - whatever our adventures may be. We'll end the day starry-eyed and look back on the photos like it was a little legacy.
        //   </p>
        // </div>
      }
    />
  );
};

export default WeddingsInfo;
