import React, {Fragment} from 'react';
import Navigation from './Navigation';
import Footer from './Footer';
import { InstagramIcon, OpenNewWindowIcon } from './Icons';

const InstagramIconSection = props => {
  return (
    <a href={`https://www.instagram.com/${props.instagramHandle}/`} target="_blank" className="link-container" rel="noreferrer">
      <InstagramIcon />
      <span>@{props.instagramHandle}<OpenNewWindowIcon /></span>
    </a>
  );
};

export const TemplateBasics = props => {
  return (
    <Fragment>
      <Navigation />
      {props.content}
      <Footer />
    </Fragment>
  );
};

const TemplateSubNavigation = props => {
  return (
    <Fragment>
      {props.subNavigation}
        <div className="layout-spacing">
          <h5>{props.pageCategory}</h5>
          <h2>{props.pageTitle}</h2>
          <InstagramIconSection {...props} />
        </div>
    </Fragment>
  );
};

export const TemplateHome = props => {
  return (
    <TemplateBasics 
      content={
        <Fragment>
          <TemplateSubNavigation {...props} />
          {props.mainContent}
        </Fragment>
      }
    />
  );
};

export const TemplatePhotosWithInfo = props => {
  return (
    <TemplateBasics 
      content={
        <Fragment>
          <TemplateSubNavigation {...props} />
          <div className="layout-3-to-1 layout-spacing space-above">
            <h3>{props.sectionTitle}</h3>
            <div className="description">
              {props.description}
              {props.details}
            </div>
            <div className="gallery">
              {props.gallery}
            </div>
          </div>
        </Fragment>
      }
    />
  );
};

export const TemplatePhotos = props => {
  return (
    <TemplateBasics 
    content={
      <Fragment>
          <TemplateSubNavigation {...props} />
          {props.galleryContent}
        </Fragment>
      }
      />
      );
    };
    
export const TemplateGallery = props => {
  return (
    <div className="layout-full layout-spacing space-above">
      <div className="title">
        <h3>{props.names}</h3>
        <span>
          <b>Location: </b>
          <u>{props.location}</u>
        </span>
      </div>
      <div className="gallery">
        {props.gallery}
      </div>
    </div>
  );
};
