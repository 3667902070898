import React, {Fragment} from 'react';
import { TemplateHome } from '../components/Template';
import PortraitsNavigation from './PortraitsNavigation';
import { InstagramIcon } from '../components/Icons';

const PortraitsInfo = () => {
  return (
    <TemplateHome
      subNavigation={ <PortraitsNavigation /> }
      pageCategory="Portraits"
      pageTitle="Info & Service Details"
      instagramHandle="hello.moonglow"
      mainContent={
        <p className="body paragraph-center-reading">Coming soon...</p>
        // <Fragment>
        //   <p>Neptú Portraits is a fine art commercial photography studio.</p>
        //   <br />
        //   <p>Equipped with professional-grade lights, stands, backdrops, props and other equipments, the studio is divided into 2 compact sections - a photography section and a preparation section. The photography section has 3 stations - a portrait station, a product station and a ballet station. The preparation section consists of a bright make-up station and a wardrobe station.</p>
        //   <br />
        //   <p>The photography studio is located in central Tartu, Estonia in a beautiful and quiet neighbourhood. Visits by appointment only.</p>
        // </Fragment>
      }
    />
  );
};

export default PortraitsInfo;
